import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, output, signal } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { map } from 'rxjs';

export class BeforeSubmitEvent extends CustomEvent<MouseEvent> {
  constructor(event: MouseEvent) {
    super('before-submit', { bubbles: false, cancelable: true, detail: event });
  }
}

@Component({
  selector: 'ideal-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormsModule, MatButtonModule],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent {
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  protected readonly formGroupDirective = inject(FormGroupDirective, { self: true });

  public readonly formGroup = input.required<FormGroup>();
  public readonly submitLabel = input<string>('Submit');
  public readonly resetLabel = input<string>('Reset');

  public readonly beforeSubmit = output<BeforeSubmitEvent>();
  public readonly formSubmit = output<SubmitEvent>();
  public readonly formReset = output<Event>();

  protected readonly formDisabled = signal<boolean>(false);

  protected onSubmit(event: SubmitEvent): void {
    event.stopImmediatePropagation();
    const form = this.formGroup();
    form.markAllAsTouched();
    if (form.invalid) {
      this.focusError();
    } else {
      this.formSubmit.emit(event);
    }
  }

  protected onSubmitClick(event: MouseEvent): void {
    const evt = new BeforeSubmitEvent(event);
    this.beforeSubmit.emit(evt);
    if (evt.defaultPrevented) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  protected onReset(event: Event): void {
    event.stopImmediatePropagation();
    this.focus();
    this.formReset.emit(event);
  }

  constructor() {
    effect(
      (onCleanup) => {
        const formGroup = this.formGroup();
        const sub = formGroup.statusChanges.pipe(map(() => formGroup.disabled)).subscribe((v) => this.formDisabled.set(v));
        onCleanup(() => {
          sub.unsubscribe();
        });
      },
      { allowSignalWrites: true },
    );
  }

  public focus() {
    this.focusSelector('input, textarea, select');
  }
  public focusError() {
    this.focusSelector('input.ng-invalid, textarea.ng-invalid, select.ng-invalid');
  }

  private focusSelector(selector: string, filter: (el: HTMLElement) => boolean = () => true) {
    const elm = this._elementRef.nativeElement;
    const matched = Array.from(elm.querySelectorAll(selector)).find(
      (el) => el instanceof HTMLElement && el.matches(':not(:disabled):not(:read-only)') && filter(el),
    ) as HTMLElement | undefined;
    matched?.focus();
  }
}
